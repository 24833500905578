import {ActivityIndicator, Animated, View} from 'react-native';
import React from 'react';
import {SafeAny} from '@/types';
import theme from '@/style';
import Spin from '@/components/basic/spin';
import {
  get3D,
  getColor,
  getDice,
  getKerala,
  getQuick3D,
  getQuickStateOrders,
  getSatta,
  getScratchAndCasino,
  getSports,
  getStateOrders,
} from './bets.service';
import NoData from '@/components/basic/error-pages/no-data';
import {useTranslation} from 'react-i18next';
import globalStore from '@/services/global.state';
import BetsListItem from './bets-list-item';
import useInfiniteScroll from '../hooks/load-more.hooks';
import {copyText, formatDate, getDaysByDate} from '@/utils';
import Drawer, {DrawerRef} from '@/components/basic/game-drawer/game-drawer';
import SharePanel from '@/components/business/share-panel/share-panel';
import {shareToThird, shareToChat} from '@/common-pages/bets/bets.service';
import {IShareInfo} from '@/common-pages/bets/bets.service';
import {getShareAward} from '@/services/global.service';
import {TGameCode} from '@/services/global.service';
import {useShareToast} from '../hooks/shareToast.hooks';
import {getUUID, transfor} from '@/utils';

const map: {[key: string]: TGameCode} = {
  Kerala: 'kerala',
  '3 Digit': '3Digit',
  'Quick 3D': 'quick3d',
  Color: 'color',
  Dice: 'dice',
  'Satta Matka': 'matka',
  'Quick Race': 'quickRace',
  'Scratch off': 'scratch',
  Casino: 'casino',
  Live: 'live',
  Sports: 'sport',
  'State Lottery': 'stateLottery',
  'Quick State Lottery': 'quickStateLottery',
};

const getOrderStatus = (gameName: string, status: string) => {
  if (status === 'ALL') {
    return '3';
  }
  if (
    [
      'Kerala',
      '3 Digit',
      'Quick 3D',
      'State Lottery',
      'Quick State Lottery',
    ].includes(gameName)
  ) {
    return status === 'UNKNOWN' ? '2' : status === 'KNOWN' ? '0' : '1';
  } else if (['Dice', 'Color'].includes(gameName)) {
    return status === 'UNKNOWN' ? '0' : status === 'KNOWN' ? '4' : '1';
  } else if (gameName === 'Satta Matka') {
    return status === 'UNKNOWN' ? '2' : status === 'KNOWN' ? '4' : '1';
  } else {
    return status === 'UNKNOWN' ? '0' : status === 'KNOWN' ? '4' : '1';
  }
};

const BetsList = (props: {
  currentDate?: Date;
  isActive?: boolean;
  status: string | 'ALL' | 'UNKNOWN' | 'KNOWN' | 'WON';
  game: string;
  transAnimate: SafeAny;
  scrollEnable?: boolean;
}) => {
  const {shareSuccess, shareFail} = useShareToast();
  const pageSize = 10;
  const pageRef = React.useRef(1);
  const hasMores = React.useRef(false);
  const [loading, setLoading] = React.useState(false);
  const [list, setList] = React.useState<SafeAny[]>([]);
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [moreLoading, setMoreLoading] = React.useState(false);
  const [shareAward, setShareAward] = React.useState<number>(0);
  const panelRef = React.useRef<DrawerRef>(null);
  const [threeOrderData, setThreeOrderData] = React.useState<SafeAny>({});
  const {i18n} = useTranslation();
  const {
    status,
    game,
    currentDate = new Date(),
    isActive = false,
    transAnimate,
    scrollEnable,
  } = props;

  const {onEndReachedCalledDuringMomentum} = useInfiniteScroll(
    `bets-${status}`,
  );

  React.useEffect(() => {
    if (isActive) {
      setFirstLoad(true);
      getList(true, true).then();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [game, currentDate, isActive]);

  const getList = async (refresh = false, isFirst = false) => {
    if (loading || moreLoading) {
      return;
    }
    if (refresh) {
      setList([]);
      pageRef.current = 1;
      if (!firstLoad && !isFirst) {
        setLoading(true);
      }
    } else {
      pageRef.current += 1;
      setMoreLoading(true);
    }
    const yearMonth = formatDate(currentDate, 'yyyyMM');
    try {
      let res = [];
      let data;
      const normalParams = {
        orderStatus: getOrderStatus(game, status),
        pageNo: pageRef.current,
        pageSize,
        yearMonth,
      };
      const startTime = formatDate(currentDate, 'yyyy-MM-01 00:00:00');
      const endTime = formatDate(
        getDaysByDate(currentDate),
        'yyyy-MM-dd 23:59:59',
      );
      const award = await getShareAward(map[game]);
      setShareAward(Math.floor(award));
      switch (game) {
        case 'Kerala':
          // 0:已开奖，1:已中奖，2:未开奖 3:全部
          data =
            (await getKerala({
              ...normalParams,
              // orderStatus
              lotteryType: 'KERALA',
            })) || [];
          res = data!.content || [];
          break;
        case '3 Digit':
          // 0:已开奖，1:已中奖，2:未开奖 3:全部
          data = (await get3D(normalParams)) || [];
          res = data!.content || [];
          break;
        case 'Quick 3D':
          data = await getQuick3D(normalParams);
          res = data!.records || [];
          break;
        case 'Color':
          // 0-未开奖 1-已中奖 2-未中奖 3全部
          data = await getColor(normalParams);
          res = data!.content || [];
          break;
        case 'Dice':
          // 0-未开奖 1-已中奖 2-未中奖 3全部
          data = await getDice(normalParams);
          res = data!.content || [];
          break;
        case 'Satta Matka':
          // 0未中奖1已中奖2未开奖 3全部
          res = (await getSatta(normalParams)) || [];
          break;
        case 'Quick Race':
        case 'Scratch off':
        case 'Casino':
        case 'Live':
          // 1=中奖 0=已使用 2=未使用 3全部
          const gameType =
            game === 'Scratch off'
              ? 1
              : game === 'Casino'
              ? 2
              : game === 'Quick Race'
              ? 5
              : 3;
          res =
            (await getScratchAndCasino({
              ...normalParams,
              startTime,
              endTime,
              gameType,
            })) || [];
          break;
        case 'Sports':
          // 0未中奖1已中奖2未开奖 3全部
          const result = await getSports({
            ...normalParams,
            startTime,
            endTime,
          });
          res = result.content || [];
          break;
        case 'State Lottery':
          data = await getStateOrders(normalParams);
          res = data.records || [];
          break;
        case 'Quick State Lottery':
          data = await getQuickStateOrders(normalParams);
          res = data.records || [];
          break;
      }
      setList(refresh ? res : list.concat(res));
      hasMores.current = res.length >= pageSize;
    } finally {
      setFirstLoad(false);
      setLoading(false);
      setMoreLoading(false);
    }
  };

  const onShare = (item: SafeAny) => {
    const user = localStorage.getItem('user') || '{}';
    const userId = JSON.parse(user).userId;
    setThreeOrderData({
      userId,
      data: transfor({...item, userId}, map[game], {
        from: 'order',
      }),
    });
    panelRef.current?.open();
  };

  const onLoadMore = async () => {
    if (hasMores.current && !onEndReachedCalledDuringMomentum.current) {
      await getList(false);
      onEndReachedCalledDuringMomentum.current = true;
    }
  };

  const Footer = React.useMemo(() => {
    if (moreLoading) {
      return (
        <View style={[theme.flex.center, theme.padding.l]}>
          <ActivityIndicator />
        </View>
      );
    }
    return null;
  }, [moreLoading]);

  const onCopy = (id: string) => {
    copyText(id);
    globalStore.globalSucessTotal(i18n.t('copy-success'));
  };
  return (
    <View style={[theme.background.lightGrey, theme.flex.flex1]}>
      <Spin loading={firstLoad} style={theme.flex.flex1}>
        <Animated.FlatList
          onScroll={Animated.event(
            [
              {
                nativeEvent: {
                  contentOffset: {
                    y: transAnimate,
                  },
                },
              },
            ],
            {useNativeDriver: true},
          )}
          id={`bets-${status}`}
          style={theme.flex.flex1}
          renderItem={({item}) => {
            return (
              <BetsListItem
                info={item}
                hasReward={Boolean(shareAward)}
                onShare={() => onShare(item)}
                onCopy={onCopy}
                canGoDetail
                onRefresh={() => getList(true)}
                game={game}
              />
            );
          }}
          scrollEventThrottle={16}
          onEndReachedThreshold={0.3}
          onMomentumScrollBegin={() => {
            onEndReachedCalledDuringMomentum.current = false;
          }}
          scrollEnabled={scrollEnable}
          refreshing={loading}
          onRefresh={() => {
            getList(true).then();
          }}
          onEndReached={onLoadMore}
          ListFooterComponent={Footer}
          keyExtractor={(item, index) => `${index}`}
          data={list}
          ListEmptyComponent={
            !firstLoad && !loading && list && list.length === 0 ? (
              <NoData />
            ) : null
          }
          contentContainerStyle={[
            theme.padding.lrl,
            list.length === 0 && theme.flex.flex1,
          ]}
        />
      </Spin>
      <Drawer mode="bottom" ref={panelRef} contentBackgroundColor="transparent">
        <SharePanel
          hasReward={Boolean(shareAward)}
          gameType={map[game]}
          onClose={() => panelRef.current?.close()}
          onItemPress={pt => {
            panelRef.current?.close();
            shareToThird(
              pt,
              {
                gameCode: map[game],
                orderNo: getUUID(),
                threeOrderData,
              },
              (info: IShareInfo) => shareToChat(info),
              () => {
                shareSuccess(shareAward);
                setShareAward(0);
              },
              shareFail,
            );
          }}
        />
      </Drawer>
    </View>
  );
};

export default React.memo(BetsList);
